import './cookie-prompt/bbccookies.scss';
require(['orb/cookies', 'jsonp'], function (cookies, jsonp) {

  var COOKIE_PROMPT_CLASS_NAMES = '';
  var COOKIE_PROMPT_CLASS_NAMES_FULL_SCREEN_OVERLAY = 'full-screen full-screen-bottom';
  var COOKIE_PROMPT_CLASS_NAMES_FULL_SCREEN = 'full-screen';

  // some pages require that we do not add the overlay to the fullscreen cookie banner,
  // because the user needs to interact with the page before accepting cookies
  // (e.g. the cookie settings page)
  // the page name is checked against this list

  var PAGES_WITHOUT_FULLSCREEN_BANNER_OVERLAY = ['usingthebbc.'];

  var prevPrivacyCookieValue;

  function setCookieValue(key, value) {
    var onHttps = document.location.protocol === 'https:';
    var nextYear = new Date();
    nextYear.setYear(nextYear.getFullYear() + 1);
    return cookies.set(key, value, {
      domain: cookies._getCurrentDomain(),
      path: '/',
      expires: nextYear,
      sameSite: 'None',
      secure: onHttps
    });
  }

  function optInToAllCookiesCrossDomain() {
    var newCookiePolicy = {
      ads:true,
      personalisation:true,
      performance: true,
      necessary:true
    };
    return cookies.setCookiePolicyCrossDomain(newCookiePolicy);
  }

  function getExplicitConsentFlag() {
    return cookies.get(cookies.EXPLICIT_COOKIE_NAME);
  }

  function policyIsExplicit(region) {
    var policy = getExplicitConsentFlag();
    if (policy === null) {
      return false;
    }
    if (region === 'gb' || region === 'row') {
      return policy >= cookies.EXPLICIT_FLAG;
    }
    if (region === 'eu') {
      return policy === cookies.EXPLICIT_FLAG_EU;
    }
    throw new Error('region not recognised: ' + region);
  }

  function showPrivacyBanner(container, privacyPromptElement, cookiePromptElement) {
    privacyPromptElement.style.display = 'inline-block';
    privacyPromptElement.style.width = '100%';
    cookiePromptElement.style.display = 'none';
    container.className = COOKIE_PROMPT_CLASS_NAMES;
  }

  function hideAllBanners(container, privacyPromptElement, cookiePromptElement) {
    privacyPromptElement.style.display = 'none';
    cookiePromptElement.style.display = 'none';
    container.className = COOKIE_PROMPT_CLASS_NAMES;
  }

  // This returns appropriate urlPrefix based on whether the page belongs to ORB or Orbit.
  function getUrlPrefix() {
    var urlPrefix = 'https://bbc.com';
    var matches = window.location.host.match(/(bbc(?:\.co\.uk))(:\d+)?(\/.*)?$/i);
    if (matches && matches[1] === 'bbc.co.uk') {
      urlPrefix = urlPrefix.replace('bbc.com', 'bbc.co.uk');
    }
    return urlPrefix;
  }

  function showCookieBannerIfPolicyNotExplicit(container, privacyPrompt, cookiePrompt, region, pageName) {
    if (policyIsExplicit(region)) {
      return;
    }

    function getClassName(pageName) {
      var pages = PAGES_WITHOUT_FULLSCREEN_BANNER_OVERLAY;
      for (var i=0; i<pages.length; i++) {
        if (pageName.search(pages[i]) > -1) {
          return COOKIE_PROMPT_CLASS_NAMES_FULL_SCREEN;
        }
      }
      return COOKIE_PROMPT_CLASS_NAMES_FULL_SCREEN_OVERLAY;
    }

    var fullScreen = region === 'eu';
    var fullScreenClassName = getClassName(pageName);

    privacyPrompt.style.display = 'none';
    cookiePrompt.style.display = 'inline-block';
    cookiePrompt.style.width = '100%';
    container.className = fullScreen ? fullScreenClassName : COOKIE_PROMPT_CLASS_NAMES;
  }

  function initialiseBanners(region, pageName) {
    var container = document.getElementById('cookiePrompt');
    var privacyPrompt = document.getElementById('bbcprivacy');
    var cookiePrompt = document.getElementById('bbccookies');
    var privacyContinueButton = document.getElementById('bbcprivacy-continue-button');

    privacyContinueButton.addEventListener('click', function () {
      hideAllBanners(container, privacyPrompt, cookiePrompt);
      showCookieBannerIfPolicyNotExplicit(container, privacyPrompt, cookiePrompt, region, pageName);
    });

    var cookieContinueButton = document.getElementById('bbccookies-continue-button');

    cookieContinueButton.addEventListener('click', function () {
      hideAllBanners(container, privacyPrompt, cookiePrompt);

      optInToAllCookiesCrossDomain();
      if (region === 'gb') {
        setCookieValue(cookies.EXPLICIT_COOKIE_NAME, cookies.EXPLICIT_FLAG);
        cookies.setCookieExplicitCrossDomain(cookies.EXPLICIT_FLAG);
      }
      if (region === 'eu') {
        setCookieValue(cookies.EXPLICIT_COOKIE_NAME, cookies.EXPLICIT_FLAG_EU);
        cookies.setCookieExplicitCrossDomain(cookies.EXPLICIT_FLAG_EU);
      }
    });

    var cookieSettingsLink = document.getElementById('bbccookies-settings')
      .getElementsByTagName('a')[0];

    cookieSettingsLink.addEventListener('click', (function () {
      var clicked = false;

      return function (event) {
        if (!clicked) {
          cookies.copyPolicyCookiesCrossDomain(function () {
            clicked = true;
            //'re-click' the link to trigger the suppressed navigation
            //and cause istats to fire; it relies on the propagated click event
            cookieSettingsLink.click();
          });
          if (region === 'gb') {
            setCookieValue(cookies.EXPLICIT_COOKIE_NAME, cookies.EXPLICIT_FLAG);
            cookies.setCookieExplicitCrossDomain(cookies.EXPLICIT_FLAG);
          }
          if (region === 'eu') {
            setCookieValue(cookies.EXPLICIT_COOKIE_NAME, cookies.EXPLICIT_FLAG_EU);
            cookies.setCookieExplicitCrossDomain(cookies.EXPLICIT_FLAG_EU);
          }
          //suppress the initial navigation
          event.preventDefault();
        }
      };

    })());
    showCookieBannerIfPolicyNotExplicit(container, privacyPrompt, cookiePrompt, region, pageName);

    var shouldSeePrivacyBanner = false;

    // if cookie exists and value is different to current value then show banner
    if(prevPrivacyCookieValue !== undefined && prevPrivacyCookieValue !== null && prevPrivacyCookieValue !== cookies.CURRENT_PRIVACY_POLICY){
      shouldSeePrivacyBanner = true;
    }
    if (shouldSeePrivacyBanner) {
      showPrivacyBanner(container, privacyPrompt, cookiePrompt);
    } else {
      showCookieBannerIfPolicyNotExplicit(container, privacyPrompt, cookiePrompt, region, pageName);
    }
  }

  function getSanitisedLanguage(locale) {
    var lang = 'en';

    if (typeof(locale) === 'string' && locale.length >= 2) {
      lang = locale.trim().substring(0, 2).toLowerCase();
    }

    var localeIsSupported = ['en', 'cy', 'gd', 'ga'].indexOf(lang) !== -1;

    if (!localeIsSupported) {
      return 'en';
    }
    return lang;
  }

  function loadContentOfBanners(locale, initialiseBanners, region, pageName) {
    var lang = getSanitisedLanguage(locale);

    var CSS_URL = window.COOKIES_STATIC_HOST + 'bbccookies.min.css';
    var url = window.COOKIES_STATIC_HOST + lang + '.min.js';

    window.bbcpage.loadCSS(CSS_URL).then(function () {
      jsonp(
        url,
        { name: 'cookiePrompt' },
        function (err, template) {
          var parsedHTML = template.replace(/<<bbcUrlPrefix>>/g, getUrlPrefix());
          document.getElementById('cookiePrompt').innerHTML = parsedHTML;

          var elements = document.querySelectorAll('[data-region-filter]');
          for (var i=0; i<elements.length; i++) {
            var el = elements[i];
            if (el.getAttribute('data-region-filter') !== region) {
              el.style.display = 'none';
            }
          }
          initialiseBanners(region, pageName);
        }
      );
    }.bind(this));
  }

  Promise.all([
    window.bbcuser.isUKCombined(),
    window.bbcuser.getCountry(),
    window.bbcpage.getLanguage(),
    window.bbcpage.getName()
  ]).then(function (conditions) {

    var isUK = conditions[0];
    var countryCode = conditions[1];
    var language = conditions[2];
    var pageName = conditions[3];

    var isEU = cookies.isEU(countryCode);

    var region;

    if (isUK) {
      region = 'gb';
    } else if (isEU) {
      region = 'eu';
    } else {
      region = 'row';
    }

    if (!policyIsExplicit(region)) {
      setCookieValue(cookies.EXPLICIT_COOKIE_NAME, cookies.IMPLICIT_FLAG);
      cookies.setImplicitPolicy(region);
    }

    if (region === 'gb' || region === 'eu') {
      var seenPrivacyBanner = (cookies.get(cookies.PRIVACY_COOKIE_NAME) === cookies.CURRENT_PRIVACY_POLICY);

      prevPrivacyCookieValue = cookies.get(cookies.PRIVACY_COOKIE_NAME);
      if (!seenPrivacyBanner || !policyIsExplicit(region)) {
        loadContentOfBanners(language, initialiseBanners, region, pageName);
      }
      setCookieValue(cookies.PRIVACY_COOKIE_NAME, cookies.CURRENT_PRIVACY_POLICY);
    }
  }).catch(function () {
    loadContentOfBanners('en', initialiseBanners, 'gb', '');
  });
});
